<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      background-color: #f6f6f6;
    "
  >
    <!-- <div ref="popupDialog" :class="'popup-dialog-' + 1">
      <el-image :src="$http.defaults.baseURL + '1212'" />
      <p>vue广告悬浮1</p>
    </div> -->
    <header>
      <div class="head1">
        <div class="minqing"></div>
      </div>
    </header>

    <!-- <el-image
      v-if="state.model.HomeTopImg"
      :src="$http.defaults.baseURL + state.model.HomeTopImg"
      style="width: 100%"
    />
    <el-image
      v-else
      :src="require('@/assets/images/logo.png')"
      style="width: 100%"
    /> -->
    <div style="background-color: #306eb2">
      <div class="index-nav">
        <el-dropdown
          size="large"
          type="primary"
          popper-class="index-nav-drop-popper"
        >
          <span style="color: #fff" @click="$router.push('/')">首页</span>
        </el-dropdown>
        <el-dropdown
          size="large"
          type="primary"
          popper-class="index-nav-drop-popper"
          v-for="item in state.menuData"
          :key="item"
        >
          <span style="color: #fff">{{ item.Name }}</span>
          <template #dropdown v-if="item.Children.length > 0">
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="child in item.Children"
                :key="child"
                style="font-size: 140%"
                @click="onMenuClick(child)"
                >{{ child.Name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <li style="flex: 1; text-align: right">
          <el-input
            v-model="state.key"
            input-style="padding:0 10px"
            style="
              width: 10vw;
              border-radius: 50px;
              overflow: hidden;
              padding: 0;
            "
            placeholder="站内搜索"
            :suffix-icon="Search"
          />
        </li>
      </div>
    </div>
    <router-view />
    <div class="footer">
      <li>版权所有©2020 安徽省合肥汽车客运有限公司</li>
      <li>地址:合肥市包河区庐州大道与龙川路交口西南角</li>
      <li>邮编:230051</li>
      <li>
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: #fff; text-decoration: none"
          >皖B1.B2-20150041-3</a
        >
        皖公安网备34011102001815
      </li>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive } from "vue";
import { home_getconfig, home_getmenu } from "@/http/api";
import { useRouter } from "vue-router";
import CryptoJS from "crypto-js";
import { secretKey } from "@/utils/const";

export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      model: {},
      menus: [],
    });
    onMounted(() => {
      //绑定监听事件
      window.addEventListener("keydown", keyDown);
      home_getconfig().then((res) => {
        console.log(res.data);

        if (res.status == 200) state.model = res.data;
      });

      home_getmenu().then((res) => {
        if (res.status == 200) state.menuData = res.data;
      });
    });

    onUnmounted(() => {
      //销毁事件
      window.removeEventListener("keydown", keyDown, false);
    });

    //点击回车键登录
    const keyDown = (e) => {
      if (e.keyCode == 13) {
        onSearch();
      }
    };

    const onMenuClick = (menu) => {
      if (menu.Template == 7) onOpenLink(menu.LinkUrl);
      else router.push("/main/m/" + menu.Id);
    };

    const onOpenLink = (url) => {
      if (url.includes("http://") || url.includes("https://"))
        window.open(url, "_blank");
      else window.open("http://" + url, "_blank");
    };

    const onSearch = () => {
      router.push(
        "/search/" +
          encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify(state.key),
              secretKey
            ).toString()
          )
      );
    };

    return {
      state,
      onMenuClick,
    };
  },
};
</script>
<style scoped lang="less">
.head1 {
  background: url("../assets/images/logo.png") center no-repeat;
  width: 100%;
  height: 160px;
  background-color: white;
}

.minqing {
  width: 1200px;
  margin: 0 auto;
}

.index-nav {
  background-color: #306eb2;
  display: flex;
  align-items: center;
  color: #fff;
  height: 50px;
  width: 1200px;
  margin: 0 auto;

  & > .el-dropdown {
    padding: 0 10px;
    font-size: 120%;
    height: 100%;
    line-height: 50px;
    cursor: pointer;
    &:hover {
      border-top: 3px solid #ffa500;
    }
  }
}

.footer {
  background: #306eb2;
  width: 100%;
  margin-top: 34px;
  padding: 1vh 0;

  li {
    margin-top: 7px;
    text-align: center;
    color: #ffffff;
  }
}

.popup-dialog-1 {
  top: 0;
  left: 20px;
}

.popup-dialog-2 {
  top: 0;
  right: 20px;
}

.popup-dialog-3 {
  bottom: 0;
  left: 20px;
}

.popup-dialog-4 {
  bottom: 0;
  right: 20px;
}
</style>
<style lang="less">
.index-nav-drop-popper {
  background-color: #a3d0ec !important;
  margin-top: -12px;
  border-radius: 0;
  border: 0 !important;

  .el-popper__arrow {
    display: none !important;
  }

  .el-dropdown-menu {
    background-color: inherit;

    .el-dropdown-menu__item {
      color: #fff !important;

      &:hover {
        color: #306eb2 !important;
      }
    }
  }
}

.carousel-box1 {
  .el-carousel__indicator {
    .el-carousel__button {
      width: 15px;
      height: 15px;
      background: #e2e2e2;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
    }
  }

  .el-carousel__indicators--horizontal {
    right: 0 !important;
    left: auto;
  }
}

.carousel-box2 {
  .el-carousel__indicator {
    .el-carousel__button {
      width: 15px;
      height: 15px;
      background-color: #fff !important;
      opacity: 1;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
    }
  }

  .is-active {
    .el-carousel__button {
      background-color: #f00 !important;
    }
  }

  .el-carousel__indicators--horizontal {
    right: 0 !important;
    left: auto;
  }
}

.aeds {
  position: relative;
  width: 100%;
  height: 100%;

  &::after {
    content: attr(alt);
    width: calc(100% - 20px);
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
